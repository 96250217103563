<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.FormTemplates",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <router-link
        :to="{
          path: '/ProjectDesign/FormTemplate/List',
        }"
        class="btn btn-action-list-page"
      >
        <i class="bi bi-list"></i>
        {{
          $t(
            "FormTemplates.AllData",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
      <router-link
        :to="{
          name: 'CustomObjectNewFormTemplate',
          params: { customObjectId: this.$route.params.customObjectId },
        }"
        class="btn btn-success btn-action-new-page"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "FormTemplates.NewButton",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
    </div>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onTranslationButtonClick="onTranslationButtonClick"
  />
  <TranslationModal
    :keyTypeId="keyTypeId"
    :parentPublicId="customObjectId"
    :formulaName="formulaName"
    :keyName="keyName"
    :keyItemPublicId="keyItemPublicId"
  />
</template>
<script>
import { showModal } from "@/core/helpers/dom";
export default {
  name: "CustomObjectFormTemplates",
  data() {
    return {
      keyTypeId: 12,
      keyName: "",
      customObjectId: "",
      keyItemPublicId: "",
      formulaName: "",
      gridSettings: {
        action: "CustomObjectFormTemplates",
        requestUrl: String.format("/Lcdp-FormTemplateList"),
        requestUrlRouteParamNames: [
          {
            key: "customObjectPublicId",
            value: "customObjectId",
          },
        ],
        routeRequiredParameters: [
          {
            key: "formTemplateId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        serialNoLink: `#/CustomObject/EditFormTemplate/__CUSTOMOBJECTPUBLICID__&formTemplateId=`,

        allowSearchPanel: false,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-FormTemplateDelete",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditFormTemplate",
              params: {
                customObjectId: "",
                formTemplateId: "",
              },
            },
          },
          {},
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "FormTemplates.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "FormTemplates.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "FormTemplates.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "FormTemplates.OutputType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "outputTypeName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onTranslationButtonClick(rowData) {
      this.keyItemPublicId = rowData.publicId;
      this.customObjectId = rowData.customObjectPublicId;
      this.formulaName = rowData.formulaName;
      this.keyName = rowData.name;
      // showModal(document.getElementById("translationModal"));
    },
  },
  mounted() {
    this.gridSettings.buttons.pop();
    if (this.$store.getters._isMultiLanguage) {
      this.gridSettings.buttons.push({
        name: "translation",
        cssClass: "btn-light translation",
        iconClass: "bi bi-translate",
        routeButton: false,
        emitMethodName: "onTranslationButtonClick",
        attributes: {
          "data-bs-toggle": "modal",
          "data-bs-target": "#translationModal",
        },
        iconAttributes: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: this.$t(
            "Components.TranslationModal.Title",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      });
    }
  },
};
</script>
